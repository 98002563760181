import omit from "lodash/omit";
import pick from "lodash/pick";
// TODO: switch to path-to-regexp
import { route } from "nextjs-routes";
import type { ParsedUrlQuery } from "querystring";
import type { UrlObject } from "url";

import { COMMON_CLIENT_CONFIG } from "#config/common/client";

// UrlObject accepted by next `<Link />` component.
export type UrlOpts = Omit<UrlObject, "query"> & {
  query?: Record<string, string | number>;
};
type ParametrizedUrlOpts<PathKey extends string> = UrlOpts & {
  [key in PathKey]: string;
};

// Pre-populated url string - what u see in the browser.
type GeneratedStringUrl = string;

type PopulatedUrl = {
  asPath: (opts?: UrlOpts) => GeneratedStringUrl;
  pathname: string;
  url: (opts?: UrlOpts) => UrlOpts;
};
type PopulatedParametrizedUrl<PathKey extends string> = {
  asPath: (opts: ParametrizedUrlOpts<PathKey>) => GeneratedStringUrl;
  pathname: string;
  url: (opts: ParametrizedUrlOpts<PathKey>) => UrlOpts;
};

function urlWithParam(locale: string, path: string): PopulatedUrl;
function urlWithParam<PathKey extends string>(
  locale: string,
  path: string
): PopulatedParametrizedUrl<PathKey>;
function urlWithParam<PathKey extends string>(locale: string, path: string) {
  if (path !== "/") {
    if (path.endsWith("/")) {
      console.error(`Path '${path}' should not have trailing slash.`);
    }
    if (!path.startsWith("/")) {
      console.error(`Path '${path}' should have leading slash.`);
    }
  }

  const urlParams = [
    "auth",
    "hash",
    "host",
    "hostname",
    "href",
    "pathname",
    "protocol",
    "search",
    "slashes",
    "port",
    "query",
  ];

  const urlProps = ({
    locale,
    opts,
  }: {
    locale: string;
    opts?: UrlOpts | ParametrizedUrlOpts<PathKey>;
  }): UrlOpts => {
    const urlOpts = pick<UrlOpts>(opts, urlParams);
    const query = omit(opts, urlParams) as UrlOpts["query"];
    const prefix = locale ? `/${locale.toLowerCase()}` : "";

    return {
      ...urlOpts,
      pathname: `${prefix}${path}` as const,
      query: { ...query, ...opts?.query },
    };
  };

  return {
    pathname: path,

    // Pre-populated url string - what u see in the browser.
    asPath: (opts?: UrlOpts | ParametrizedUrlOpts<PathKey>) => {
      const { pathname, query, hash } = urlProps({ locale, opts });
      return route({
        pathname: pathname!,
        query: query as ParsedUrlQuery,
        hash,
      });
    },
    // UrlObject accepted by next `<Link />` component.
    url: (opts?: UrlOpts | ParametrizedUrlOpts<PathKey>) =>
      urlProps({ locale, opts }),
  };
}

const url = urlWithParam;

// TODO: Refine, make it DRY.
export const getPaths = (locale: string) => ({
  home: url(locale, "/"),
  error: url(locale, "/500"),
  privacyPolicy: url(locale, "/privacy-policy"),
  about: url(locale, "/about"),
  terms: url(locale, "/terms"),
  product: url<"slug">(locale, "/product/[slug]"),
  cart: url(locale, "/cart"),
  category: url<"slug">(locale, "/category/[slug]"),
  collection: url<"slug">(locale, "/collection/[slug]"),
  page: url<"slug">(locale, "/page/[slug]"),
  search: url(locale, "/search"),
  maintenance: url(locale, COMMON_CLIENT_CONFIG.MAINTENANCE_PAGE_PATH),
  account: {
    ...url(locale, "/account"),
    profile: url<"id">(locale, "/account/[id]"),
    addresses: url(locale, "/account/[id]/addresses"),
  },
  checkout: url(locale, "/checkout"),
  order: {
    ...url(locale, "/order"),
    paymentConfirmation: url<"id">(locale, "/order/confirmation/payment/[id]"),
    confirmation: url<"id">(locale, "/order/confirmation/[id]"),
  },
  api: {
    auth: {
      basic: url(locale, COMMON_CLIENT_CONFIG.BASIC_AUTH_PATH),
    },
  },
});
