"use client";

import {
  type StaticImageData,
  type StaticImport,
} from "next/dist/shared/lib/get-img-props";
import Image, { type ImageProps } from "next/image";
import { useCallback, useEffect, useState } from "react";

import { CLIENT_CONFIG } from "@/config/client";
import { imageLoader } from "@/lib/images";

import { ImagePlaceholder } from "./ImagePlaceholder";

import { cn } from "@/styles/lib";
import { type TransitionDuration } from "@/styles/types";

const isStaticImport = (src: NextImageProps["src"]) =>
  !!(src as StaticImageData)?.blurDataURL;

/**
 * Local cache to prevent showing skeleton upon going back & forth.
 */
const LOADED_IMAGES: Array<string | StaticImport> = [];
const SKELETON_TIMEOUT: TransitionDuration = "500";

export type NextImageProps = ImageProps & {
  fallbackPlaceholder?: boolean;
};

export const NextImage = ({
  fallbackPlaceholder = true,
  style,
  className,
  ...props
}: NextImageProps) => {
  const isStaticSrc = isStaticImport(props.src);
  const isAlreadyLoaded = LOADED_IMAGES.includes(props.src);

  const [state, setState] = useState<"loading" | "error" | "loaded">(
    isStaticSrc || isAlreadyLoaded ? "loaded" : "loading"
  );
  const [skeletonLoaded, setSkeletonLoaded] = useState(state === "loaded");

  const placeholder = (
    <canvas
      className={cn(
        `skeleton pointer-events-none absolute inset-0 h-full w-full transition-all duration-${SKELETON_TIMEOUT} z-0`,
        {
          "animate-none bg-none": state !== "loading",
        }
      )}
      role="status"
      style={style}
    />
  );

  const handleError = useCallback(() => {
    setState("error");
  }, [props.src]);

  const handleOnLoad = useCallback(() => {
    LOADED_IMAGES.push(props.src);
    setState("loaded");
  }, [props.src]);

  useEffect(() => {
    if (state === "loaded" && !skeletonLoaded) {
      const timeout = setTimeout(
        () => setSkeletonLoaded(true),
        Number(SKELETON_TIMEOUT) + 25
      );
      return () => clearTimeout(timeout);
    }
  }, [state]);

  return (
    <>
      {state === "error" ? (
        fallbackPlaceholder && (
          <ImagePlaceholder className={className} style={style as any} />
        )
      ) : (
        // eslint-disable-next-line jsx-a11y/alt-text
        <Image
          className={cn(
            "relative z-[1] transition-all duration-[750ms]",
            className
          )}
          loader={CLIENT_CONFIG.IMAGES_CUSTOM_LOADER ? imageLoader : undefined}
          quality={CLIENT_CONFIG.IMAGES_QUALITY}
          style={{
            ...style,
            ...(state === "loading" && !props.priority && { opacity: 0 }),
          }}
          onError={handleError}
          onLoad={handleOnLoad}
          {...(isStaticSrc && { placeholder: "blur" })}
          {...props}
        />
      )}

      {!isStaticSrc && !skeletonLoaded && placeholder}
    </>
  );
};
