import { type ImageProps } from "next/image";

import { CLIENT_CONFIG } from "@/config/client";

export const imageLoader: ImageProps["loader"] = ({ src, width, quality }) => {
  const searchParams = new URLSearchParams([
    ["w", width.toString()],
    ["q", (quality || CLIENT_CONFIG.IMAGES_QUALITY).toString()],
  ]);

  return `${src}?${searchParams.toString()}`;
};
